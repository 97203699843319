import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const pavSchema = new schema.Entity('pavs', {}, { idAttribute: 'pav_id' });
const accountSchema = new schema.Entity('accounts', {}, { idAttribute: 'account_id' });
const appHabitantSchema = new schema.Entity('appHabitants', {}, { idAttribute: 'slug' });

// Schemas for API responses.
export const Schemas = {
  ACCOUNT: accountSchema,
  ACCOUNT_ARRAY: new schema.Array(accountSchema),
  PAV: pavSchema,
  APP_HABITANT: appHabitantSchema,
  PAV_ARRAY: new schema.Array(pavSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
