import React, { useState,useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import BaseLayout from './shared/BaseLayout';
import HeaderTimeLine from '../components/shared/HeaderTimeLine';

import ClipLoader from 'react-spinners/ClipLoader';

import { Carousel } from 'react-responsive-carousel';
import { cloneDeep, isEmpty, map, includes, orderBy, isNull, filter } from 'lodash';

import MapPavSelector from '../components/shared/MapPavSelector';
import logo from '../../assets/images/logo-upcycle-couleur.svg';

import { API_URL } from '../constants/Config';
import { Form, Field } from 'react-final-form';
import { loadAppHabitants } from '../store/modules/appHabitants';

import { checkEmailExist, createAccount, setUtmsource } from '../store/modules/auth';
import { validateEmail } from '../utils/StringValidator';
import InputSearchAddress from '../components/shared/InputSearchAddress';
import ModalCGU from '../components/ModalCGU';
import countries from '../constants/countriesList';
import InputSearchAddressGoogle from '../components/shared/InputSearchAddressGoogle';

const AdhesionPage = ({
  createAccount,
  checkEmailExist,
  loadAppHabitants,
  account,
  appHabitants,
  utmSource,
  setUtmsource
}) => {
  const franceAndDomTomCodePays = ['FR', 'GP', 'MQ', 'GF', 'RE', 'YT', 'MF'];
  const [paysSelected, setPaysSelected] = useState({
    label: 'France',
    value: 'France',
    paysCode: 'FR'
  });
  const [isOpenModalCGU, setIsOpenModalCGU] = useState(false);

  const [successCreateAcccount, setSuccessCreateAcccount] = useState(false);

  const [error, setError] = useState(null);

  const [user, setUser] = useState(null);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [pavSelected, setPavSelected] = useState(null);
  const [positionSelected, setPositionSelected] = useState(null);

  const [etapeSelected, setEtapeSelected] = useState(0);
  const [slideSelected, setSlideSelected] = useState(0);

  const [etapesValidate, setEtapesValidate] = useState([false, false, false, false]);

  const [adresseInput, setAdresseInput] = useState(null);

  const { slug } = useParams();

  const selectPav = pav => {
    setPavSelected(pav);

    const userData = cloneDeep(user);

    setUser({
      email: userData.email,
      prenom: userData.prenom,
      nom: userData.nom,
      nbPersFoyer: Number(userData.nbPersFoyer),
      paysCode: paysSelected.paysCode,
      pays: paysSelected.value,
      adresse: userData.adresse,
      latitude,
      longitude,
      utm_source: (utmSource && utmSource) || null,
      pav_id: pav && pav.pav_id,
      projet_id: pav.projet_id
    });
  };

  const onSubmitInfos = values => {
    return checkEmailExist(values.email).then(isExist => {
      if (isExist && isExist.response === true) {
        setError('Un compte existe déjà avec cette adresse e-mail');
      } else {
        setError(null);
        setUser(values);
        window.scrollTo(0, 0);
        setEtapeSelected(etapeSelected + 1);
        const validate = cloneDeep(etapesValidate);
        validate[etapeSelected] = true;
        setEtapesValidate(validate);
      }
    });
  };

  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredMax20 = value =>
    value ? (value > 20 ? 'Valeur trop grande' : undefined) : 'Champ requis !';

  const requiredAdresse = value => (!isEmpty(value) ? undefined : 'Champ requis !');

  const requiredConditionGenerales = value =>
    value ? undefined : "Merci d'accepter les conditions générales";

  const submitUser = () => {
    return createAccount(user)
      .then(res => {
        window.scrollTo(0, 0);
        setEtapeSelected(etapeSelected + 1);
        const validate = cloneDeep(etapesValidate);
        validate[etapeSelected] = true;
        validate[3] = true;
        setEtapesValidate(validate);
        setError(null);
        setSuccessCreateAcccount(true);
        setUtmsource(null);
      })
      .catch(err => {
        setError(err);
        setSuccessCreateAcccount(false);
      });
  };

  const updateCurrentSlide = index => {
    if (slideSelected !== index) {
      setSlideSelected(index);
    }
  };

  const openModalCGU = () => {
    setIsOpenModalCGU(true);
  };

  const closeModalCGU = () => {
    setIsOpenModalCGU(false);
  };

  const etapes = [
    {
      titleTimeLine:
        appHabitants && appHabitants.inscription && appHabitants.inscription.arianeInscription1
          ? appHabitants.inscription.arianeInscription1
          : 'Charte',
      content: (
        <Fragment>
          {appHabitants && appHabitants.titleCharte ? (
            <h2>{appHabitants.titleCharte}</h2>
          ) : (
            <h2>Charte de participation</h2>
          )}
          <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            useKeyboardArrows={false}
            autoPlay={false}
            selectedItem={slideSelected}
            onChange={updateCurrentSlide}
          >
            <div className="slide-content">
              {appHabitants &&
              appHabitants.participationPages &&
              appHabitants.participationPages[0] ? (
                <Fragment>
                  {appHabitants.participationPages[0] && appHabitants.participationPages[0].fileName && (
                    <img
                      src={`${API_URL}/uploads/${appHabitants.participationPages[0].fileName}`}
                    />
                  )}
                  <div className="slide-title">{appHabitants.participationPages[0].sousTitre}</div>
                  <div className="slide-text">{appHabitants.participationPages[0].text}</div>
                </Fragment>
              ) : (
                <Fragment>
                  <img src={require(`../../assets/images/upcycle-charte-1.jpg`)} />
                  <div className="slide-title">
                    Dans ma cuisine : je collecte mes déchets alimentaires
                  </div>
                  <div className="slide-text">
                    Chaque jour je mets mes déchets alimentaires dans un <strong>sac kraft</strong>{' '}
                    et le bioseau qui m'a été fourni.
                  </div>
                </Fragment>
              )}
            </div>
            <div className="slide-content">
              {appHabitants &&
              appHabitants.participationPages &&
              appHabitants.participationPages[1] ? (
                <Fragment>
                  {appHabitants.participationPages[1] && appHabitants.participationPages[1].fileName && (
                    <img
                      src={`${API_URL}/uploads/${appHabitants.participationPages[1].fileName}`}
                    />
                  )}

                  <div className="slide-title">{appHabitants.participationPages[1].sousTitre}</div>
                  <div className="slide-text">{appHabitants.participationPages[1].text}</div>
                </Fragment>
              ) : (
                <Fragment>
                  <img src={require(`../../assets/images/upcycle-charte-2.jpg`)} />
                  <div className="slide-title">
                    Dans ma rue : je dépose mon sac kraft au Point d'Apport Volontaire (PAV)
                  </div>
                  <div className="slide-text">
                    1 fois par semaine , je le déverrouille grâce à mon <strong>code usager</strong>
                    .
                  </div>
                </Fragment>
              )}
            </div>
            <div className="slide-content">
              {appHabitants &&
              appHabitants.participationPages &&
              appHabitants.participationPages[2] ? (
                <Fragment>
                  {appHabitants.participationPages[2].fileName && (
                    <img
                      src={`${API_URL}/uploads/${appHabitants.participationPages[2].fileName}`}
                    />
                  )}
                  <div className="slide-title">{appHabitants.participationPages[2].sousTitre}</div>
                  <div className="slide-text">{appHabitants.participationPages[2].text}</div>
                </Fragment>
              ) : (
                <Fragment>
                  <img src={require(`../../assets/images/upcycle-charte-3.jpg`)} />
                  <div className="slide-title">
                    Des opérateurs de mon quartier collectent les PAV pour remplir un composteur
                  </div>
                  <div className="slide-text">
                    Je m'engage à <strong>respecter les consignes de tri</strong> qui me seront
                    envoyées par mail pour leur faciliter la vie.
                  </div>
                </Fragment>
              )}
            </div>
          </Carousel>
          <div className="bottom-nav">
            {slideSelected > 0 && (
              <div className="prev">
                <button
                  onClick={() => setSlideSelected(slideSelected - 1)}
                  className="btn btn-secondary"
                >
                  <i className="far fa-angle-left" />
                </button>
              </div>
            )}

            {slideSelected < 2 && (
              <div className="next">
                <button
                  onClick={() => setSlideSelected(slideSelected + 1)}
                  className="btn btn-secondary"
                >
                  <i className="far fa-angle-right" />
                </button>
              </div>
            )}

            {slideSelected === 2 && (
              <div className="next">
                <button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    const validate = cloneDeep(etapesValidate);
                    validate[etapeSelected] = true;
                    setEtapesValidate(validate);
                    setEtapeSelected(1);
                    setSlideSelected(2);
                  }}
                  className="btn"
                >
                  <span>J'accepte la charte</span>
                  <i className="far fa-angle-right" />
                </button>
              </div>
            )}
          </div>
        </Fragment>
      )
    },
    {
      titleTimeLine:
        appHabitants && appHabitants.inscription && appHabitants.inscription.arianeInscription2
          ? appHabitants.inscription.arianeInscription2
          : 'Infos',
      content: (
        <Fragment>
          <h2>Mes informations</h2>
          <Form
            onSubmit={onSubmitInfos}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field validate={requiredEmail} name="email" component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input
                        {...input}
                        onChange={e => {
                          input.onChange(e); //final-form's onChange
                          setError(null);
                        }}
                        type="email"
                        placeholder="exemple@gmail.com"
                      />
                      <label>
                        Email <span>*</span>
                      </label>
                    </div>
                  )}
                </Field>

                <Field validate={required} name="prenom" component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>
                        Prénom <span>*</span>
                      </label>
                    </div>
                  )}
                </Field>

                <Field validate={required} name="nom" component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>
                        Nom <span>*</span>
                      </label>
                    </div>
                  )}
                </Field>

                <Field
                  validate={requiredMax20}
                  name="nbPersFoyer"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input type="number" {...input} max="20" />
                      <label>
                        Nombre de personnes dans mon foyer <span>*</span>
                      </label>
                    </div>
                  )}
                </Field>
                <Field name="pays" validate={required} initialValue="France">
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          onChange={value => {
                            input.onChange(value);
                            setPaysSelected(value);
                          }}
                          value={paysSelected}
                          options={map(
                            orderBy(
                              countries,
                              [
                                c =>
                                  c.translations.fr.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                              ],
                              ['asc']
                            ),
                            c => ({
                              paysCode: c.alpha2Code,
                              value: c.name,
                              label: c.translations.fr
                            })
                          )}
                          placeholder="Sélectionner votre pays"
                          classNamePrefix="select"
                        />
                        <label>
                          Pays <span>*</span>
                        </label>
                      </div>
                    );
                  }}
                </Field>
                <Field name="adresse" validate={requiredAdresse} className="field">
                  {({ input, meta }) => (
                    <div className="field" onChange={e => setAdresseInput(e.target.value)}>
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}

                      {isEmpty(input.value) && adresseInput && adresseInput.length > 3 && (
                        <div className="field-error">
                          Veuillez sélectionner une proposition , dans la liste !{' '}
                        </div>
                      )}

                      {includes(franceAndDomTomCodePays, paysSelected.paysCode) ? (
                        <Fragment>
                          <InputSearchAddress
                            positionSelected={positionSelected}
                            classInput="input-adressse"
                            onChangeInputProps={value => {
                              input.onChange(value);
                            }}
                            onChangePositionProps={position => {
                              if (!isNull(position)) {
                                setPositionSelected(position);
                                setLatitude(position[0]);
                                setLongitude(position[1]);
                              } else {
                                setPositionSelected(null);
                              }
                            }}
                          />
                        </Fragment>
                      ) : (
                        <InputSearchAddressGoogle
                          paysCode={paysSelected.paysCode}
                          positionSelected={positionSelected}
                          onChangeInputProps={value => {
                            input.onChange(value);
                          }}
                          onChangePositionProps={position => {
                            if (!isNull(position)) {
                              setPositionSelected([
                                position && position.lat,
                                position && position.lng
                              ]);
                              setLatitude(position && position.lat);
                              setLongitude(position && position.lng);
                            } else {
                              setPositionSelected(null);
                            }
                          }}
                        />
                      )}

                      <label>
                        Adresse <span>*</span>
                      </label>
                    </div>
                  )}
                </Field>
                {appHabitants && appHabitants.formulaire && (
                  <div className="field">{appHabitants.formulaire}</div>
                )}
                <Field
                  name="conditions"
                  validate={requiredConditionGenerales}
                  type="checkbox"
                  component="input"
                >
                  {({ input, meta }) => (
                    <div className="field field-checkbox">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input id="terms" {...input} type="checkbox" />
                      <label htmlFor="terms">
                        J'accepte les
                        <div
                          className="label-link"
                          onClick={() => !isOpenModalCGU && openModalCGU()}
                        >
                          <ModalCGU
                            appHabitants={appHabitants}
                            isOpenModalCGU={isOpenModalCGU}
                            closeModalCGU={() => closeModalCGU()}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                </Field>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <div className="bottom-nav">
                  <div className="prev">
                    <button
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setEtapeSelected(etapeSelected - 1);
                      }}
                      className="btn btn-secondary"
                    >
                      <i className="far fa-angle-left" />
                      <span>Précédent</span>
                    </button>
                  </div>
                  <div className="next">
                    <button disabled={!positionSelected} type="submit" className="btn">
                      <span>Suivant</span>
                      <i className="far fa-angle-right" />
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </Fragment>
      )
    },
    {
      titleTimeLine:
        appHabitants && appHabitants.inscription && appHabitants.inscription.arianeInscription3
          ? appHabitants.inscription.arianeInscription3
          : 'PAV',
      content: (
        <Fragment>
          <h2>
            {appHabitants &&
            appHabitants.inscription &&
            appHabitants.inscription.inscriptionLocalisationPav
              ? appHabitants.inscription.inscriptionLocalisationPav
              : "Mon Point d'Apport Volontaire"}
          </h2>
          <div className="map">
            {positionSelected && (
              <MapPavSelector
                isSearchPositionProche
                onChangePositionFunc={position => {
                  setPositionSelected(position);
                }}
                paysCode={paysSelected.paysCode}
                pavSelected={pavSelected}
                positionSelected={positionSelected}
                selectPav={pav => selectPav(pav)}
              />
            )}
          </div>

          {error && <p style={{ color: 'red' }}>Erreur : {error}</p>}
          <div className="bottom-nav">
            <div className="prev">
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setEtapeSelected(etapeSelected - 1);
                }}
                className="btn btn-secondary"
              >
                <i className="far fa-angle-left" />
                <span>Précédent</span>
              </button>
            </div>
            <div className="next">
              <button
                disabled={!pavSelected && !successCreateAcccount}
                onClick={() => {
                  submitUser();
                }}
                className="btn"
              >
                {account && account.loading ? (
                  <Fragment>
                    <ClipLoader size={15} color={'white'} loading={account && account.loading} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <span>Suivant</span>
                    <i className="far fa-angle-right" />
                  </Fragment>
                )}
              </button>
            </div>
          </div>
        </Fragment>
      )
    },
    {
      titleTimeLine:
        appHabitants && appHabitants.inscription && appHabitants.inscription.arianeInscription4
          ? appHabitants.inscription.arianeInscription4
          : 'Code',
      content: (
        <div className="achieve-step-message">
          <h2>
            {appHabitants && appHabitants.confirmationTitre
              ? appHabitants.confirmationTitre
              : 'Merci pour votre participation !'}
          </h2>
          <h3>
            {appHabitants && appHabitants.confirmationSousTitre ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: appHabitants.confirmationSousTitre
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: `Pour accéder au PAV, <strong>tapez votre code d’accès et appuyez sur <span class="text-btn-enter">ent</span></strong>.`
                }}
              />
            )}
          </h3>
          <div className="highlight-value">
            {account && account.user && account.user.code}
            <img className="btn-enter" src={require(`../../assets/images/bouton-enter.png`)} />
          </div>
          <div className="description">
            <i className="fal fa-envelope-open-text" />
            {appHabitants && appHabitants.confirmationPictoText ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: appHabitants.confirmationPictoText
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: `Pour accéder au PAV, <strong>tapez votre code d’accès et appuyez sur <span class="text-btn-enter">ent</span></strong>.`
                }}
              />
            )}
          </div>
          <div className="btn-group flexColumn center">
            <NavLink
              to={slug ? '/' + slug + '/benefices-quartier' : '/benefices-quartier'}
              className="btn"
            >
              {appHabitants && appHabitants.confirmationBouton
                ? appHabitants.confirmationBouton
                : 'Je découvre les bénéfices pour mon quartier'}
            </NavLink>
            <NavLink to={'/' + (slug ? slug : '')} className="btn btn-quaternary">
              Fermer
            </NavLink>
          </div>
          <div className="version">
            <img className="logo-version" src={logo} />
          </div>
        </div>
      )
    }
  ];


  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);

  return (
    <BaseLayout appHabitants={appHabitants}>
      <HeaderTimeLine
        etapeSelected={etapeSelected}
        etapesValidate={etapesValidate}
        etapes={etapes}
        slug={slug}
      />
      <main>
        <div className="container">{etapes[etapeSelected].content}</div>
      </main>
    </BaseLayout>
  );
};

AdhesionPage.propTypes = {
  createAccount: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  let slug = props?.match?.params?.slug || null;
  return {
    account: state.auth,
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {},
    utmSource: state.auth.utm_source
  };
};

export default connect(mapStateToProps, { createAccount, loadAppHabitants,checkEmailExist, setUtmsource })(
  AdhesionPage
);
