import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_PAVS_REQUEST = 'upcwa/FETCH_ALL_PAVS_REQUEST';
export const FETCH_ALL_PAVS_SUCCESS = 'upcwa/FETCH_ALL_PAVS_SUCCESS';
const FETCH_ALL_PAVS_FAILURE = 'upcwa/FETCH_ALL_PAVS_FAILURE';

export const pavsActionsHandlers = {
  [FETCH_ALL_PAVS_REQUEST]: state =>
    flow(set('loaded.pavs', false), set('loading.pavs', true))(state),
  [FETCH_ALL_PAVS_SUCCESS]: (state, action) =>
    flow(
      set('entities.pavs', action.response.entities.pavs || []),
      set('loaded.pavs', true),
      set('loading.pavs', false)
    )(state),
  [FETCH_ALL_PAVS_FAILURE]: state =>
    flow(set('loaded.pavs', false), set('loading.pavs', false))(state)
};

export function loadPavs() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_PAVS_REQUEST, FETCH_ALL_PAVS_SUCCESS, FETCH_ALL_PAVS_FAILURE],
      method: 'GET',
      endpoint: '/pavs',
      schema: Schemas.PAV_ARRAY,
      successMessage: 'PAVS chargés avec succès'
    }
  };
}
