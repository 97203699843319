import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { loadAppHabitants } from '../store/modules/appHabitants';

import MapPavSelector from '../components/shared/MapPavSelector';
import { find, filter } from 'lodash';
import BaseLayout from './shared/BaseLayout';

const TrouverPavPage = ({ account, pavs, appHabitants, loadAppHabitants }) => {
  const { slug } = useParams();
  const [pavSelected, setPavSelected] = useState(null);
  const pavAssocieUser =
    account.user && find(pavs, pav => pav && pav.pav_id === account.user.pav_id);

  const [positionSelected, setPositionSelected] = useState([
    pavAssocieUser.latitude,
    pavAssocieUser.longitude
  ]);

  const selectPav = pav => {
    setPavSelected(pav);
  };


  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);

  return (
    <BaseLayout appHabitants={appHabitants}>
      <header>
        <NavLink to={'/' + (slug ? slug : '')} className="link">
          <i className="far fa-times"></i>
          <span>Annuler</span>
        </NavLink>
        <h2>
          {appHabitants && appHabitants.signalement && appHabitants.signalement.signalement7
            ? appHabitants.signalement.signalement7
            : 'Trouver un nouveau PAV'}
        </h2>
      </header>
      <main>
        <div className="container">
          <div className="map">
            <MapPavSelector
              paysCode={(account.user && account.user.paysCode && account.user.paysCode) || 'fr'}
              onChangePositionFunc={position => {
                setPositionSelected(position);
              }}
              pavSelected={pavSelected}
              positionSelected={positionSelected}
              selectPav={pav => selectPav(pav)}
            />
          </div>
        </div>
      </main>
    </BaseLayout>
  );
};

const mapStateToProps = (state, props) => {
  let slug = props.match.params.slug || null;
  return {
    account: state.auth,
    pavs: state.data.entities.pavs,
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {}
  };
};
export default connect(mapStateToProps, {loadAppHabitants})(TrouverPavPage);
