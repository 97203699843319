import { flow, set } from 'lodash/fp';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_APP_HABITANT_REQUEST = 'upca/FETCH_APP_HABITANT_REQUEST';
const FETCH_APP_HABITANT_SUCCESS = 'upca/FETCH_APP_HABITANT_SUCCESS';
const FETCH_APP_HABITANT_FAILURE = 'upca/FETCH_APP_HABITANT_FAILURE';

export const appHabitantsActionsHandlers = {
  [FETCH_APP_HABITANT_REQUEST]: state =>
    flow(set('loaded.appHabitants', false), set('loading.appHabitants', true))(state),
  [FETCH_APP_HABITANT_SUCCESS]: (state, action) =>
    flow(
      set('entities.appHabitants', action.response.entities.appHabitants || {}),
      set('loaded.appHabitants', true),
      set('loading.appHabitants', false)
    )(state)
};

export function loadAppHabitants(slug) {
  return {
    [CALL_API]: {
      types: [FETCH_APP_HABITANT_REQUEST, FETCH_APP_HABITANT_SUCCESS, FETCH_APP_HABITANT_FAILURE],
      method: 'GET',
      endpoint: `/app-habitants/slug/${slug}`,
      schema: Schemas.APP_HABITANT,
      successMessage: 'AppHabitants chargés avec succès'
    }
  };
}
