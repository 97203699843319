import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { filter } from 'lodash';
import { useParams } from 'react-router-dom';
import BaseLayout from './shared/BaseLayout';
import { loadAppHabitants } from '../store/modules/appHabitants';

import logo from '../../assets/images/logo-upcycle-couleur.svg';

const FaqPage = ({ appHabitants, loadAppHabitants, pavs, account }) => {
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);
  return (
    <BaseLayout appHabitants={appHabitants}>
      <NavLink className="close-btn" to={'/' + (slug ? slug : '')}>
        <i className="fal fa-times" />
      </NavLink>
      <div className="container flex-1">
        <div className="flexbox flex-1 alignCenter">
          {appHabitants && appHabitants.valueEditor ? (
            <div className="w100" dangerouslySetInnerHTML={{ __html: appHabitants.valueEditor }} />
          ) : (
            <iframe
              width="100%"
              height="500px"
              src="https://www.youtube.com/embed/tCmAAmMXSnM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
        <div className="version">
          <img className="logo-version" src={logo} />
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = (state, props) => {
  let slug = props.match.params.slug || null;

  return {
    account: state.auth,
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {},
    pavs: state.data.entities.pavs,
  };
};

export default connect(mapStateToProps, {loadAppHabitants})(FaqPage);
