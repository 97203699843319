import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import HomePage from './HomePage';

import FaqPage from './FaqPage';
import AdhesionPage from './AdhesionPage';
import PasswordOubliePage from './PasswordOubliePage';
import SignalementPage from './SignalementPage';
import BeneficesQuartierPage from './BeneficesQuartierPage';
import TrouverPavPage from './TrouverPavPage';
import MyCodePavPage from './MyCodePavPage';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const Router = function({}) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/adhesion/:utm_source?" component={AdhesionPage} />
          <Route exact path="/benefices-quartier" component={BeneficesQuartierPage} />
          <Route exact path="/password-not-found" component={PasswordOubliePage} />
          <Route exact path="/signalement" component={SignalementPage} />
          <Route exact path="/trouver-pav" component={TrouverPavPage} />
          <Route exact path="/mon-code-pav" component={MyCodePavPage} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/:slug/faq" component={FaqPage} />
          <Route exact path="/:slug/adhesion/:utm_source?" component={AdhesionPage} />
          <Route exact path="/:slug/benefices-quartier" component={BeneficesQuartierPage} />
          <Route exact path="/:slug/password-not-found" component={PasswordOubliePage} />
          <Route exact path="/:slug/signalement" component={SignalementPage} />
          <Route exact path="/:slug/trouver-pav" component={TrouverPavPage} />
          <Route exact path="/:slug/mon-code-pav" component={MyCodePavPage} />
          <Route exact path="/:slug" component={HomePage} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    account: state.auth,
    pavs: state.data.entities.pavs
  };
};

export default connect(mapStateToProps, {})(Router);
