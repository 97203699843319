import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Modal from 'react-modal';
import modalDefaultClass from '../utils/ModalDefaultClass';

export default ({ isOpenModalCGU, closeModalCGU, appHabitants }) => {
  return (
    <Fragment>
      conditions générales
      <Modal {...modalDefaultClass} isOpen={isOpenModalCGU}>
        <div className="modal-title">
          {appHabitants?.inscription?.inscriptionCGUTitle ? (
            <div
              dangerouslySetInnerHTML={{
                __html: appHabitants.inscription.inscriptionCGUTitle
              }}
            />
          ) : (
            'Conditions Générales d’Utilisation du site web de l’application web Upcycle'
          )}
          <div onClick={() => closeModalCGU()} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          {appHabitants?.inscription?.inscriptionCGU ? (
            <div
              dangerouslySetInnerHTML={{
                __html: appHabitants.inscription.inscriptionCGU
              }}
            />
          ) : (
            <div>
              <p>
                Les présentes Conditions Générales d’Utilisation, dénommées « CGU », sont un contrat
                conclu entre :
                <br />
                <br />
                Vous, ci-après dénommé l’« Utilisateur »,
                <br />
                <br />
                et
                <br />
                <br />
                UPCYCLE SAS, ci-après dénommé « l’Éditeur » ou « UPCYCLE », entreprise privée
                immatriculée au Registre du commerce et des sociétés (RCS) de Paris, sous le numéro
                B 535 091 185 et dont le siège social est 13 rue Saint Honoré 78000 Versailles (voir
                Mentions Légales), dont l’objet est d’établir les conditions d’accès et
                d’utilisation du Site web « app.upcycle.org », ses services gratuits et ses
                applications mobiles sous la version iOS et Android.
                <br />
                <br />
                Pour avoir accès au Site app.upcycle.org ainsi qu’à tous ses services, gratuits ou
                payants, et ses applications mobiles, vous devez accepter sans réserve les présentes
                Conditions Générales d’Utilisation dans leur dernière version et vous y conformer.
              </p>
              <h3>1. Définitions</h3>
              <p>
                <strong>Applications :</strong> désigne les applications mobiles ou web développées
                par UPCYCLE et, le cas échéant, ses partenaires, sous iOS et Android.
              </p>
              <p>
                <strong>Client :</strong> désigne toute personne physique achetant un produit ou un
                service en version papier ou en version numérique (numéro à l’unité, abonnement,
                achat d’ouvrages, etc.), via son compte Utilisateur ou sans créer de compte.
              </p>
              <p>
                <strong>Contenus :</strong> désigne les contenus de type textes, messages,
                commentaires, images, photographies, enregistrements sonores et/ou vidéos, et tous
                autres éléments susceptibles d’être publiés sur le Site par l’Utilisateur.
              </p>
              <p>
                <strong>Données Personnelles :</strong> désignent les informations, sous quelque
                forme que ce soit, se rapportant directement ou indirectement à une personne
                physique identifiée ou identifiable.
              </p>
              <p>
                <strong>Identifiants :</strong> désignent l’Identifiant propre de l’Utilisateur («
                login ») et son mot de passe de connexion (« password »), choisis par l’utilisateur.
              </p>
              <p>
                <strong>Informations :</strong> désignent les informations, publications, contenus,
                et, de manière générale, les données publiées sur le Site et les Services de
                l’Éditeur, dont l’utilisation est l’objet du présent Contrat, pouvant être
                consultées par les Utilisateurs et/ou achetées par les Clients.
              </p>
              <p>
                <strong>Site :</strong> désigne le Site édité par l’Éditeur, à l’adresse{' '}
                <a href="https://app.upcycle.org" target="_blank">
                  https://app.upcycle.org
                </a>
                .
              </p>
              <p>
                <strong>Site et les Services :</strong> désigne le Site https://www.app.upcycle.org
                et les services et les applications mobiles développées par l’Éditeur, le cas
                échéant, avec un partenaire ou prestataire.
              </p>
              <p>
                <strong>Utilisateur :</strong> désigne la personne qui consulte le site, quel que
                soit son statut (Client ou non, inscrit à une lettre d’information ou consultant le
                Site sans s’inscrire à aucun service).
              </p>
              <h3>2. Description des services</h3>
              <p>
                L’application web présente sur{' '}
                <a href="https://app.upcycle.org" target="_blank">
                  https://app.upcycle.org
                </a>{' '}
                permet d’offrir l’accès aux usagers désireux de déposer des déchets alimentaires
                dans des Points d’Apport Volontaires (PAV) mis en place par UPCYCLE et un ou
                plusieurs partenaires locaux.
                <br />
                <br />
                L’application web permet aux utilisateurs de s’inscrire pour utiliser le PAV.
              </p>
              <ul className="list-decimal">
                <li>
                  Dans ma cuisine : je collecte mes déchets alimentaires
                  <br />
                  Chaque jour je mets mes déchets alimentaires dans un sac kraft et le bioseau qui
                  m'a été fourni.
                </li>
                <li>
                  Dans ma rue : je dépose mon sac kraft au Point d'Apport Volontaire (PAV)
                  <br />1 fois par semaine, je le déverrouille grâce à mon code usager
                  <ul className="list-circle">
                    <li>
                      Après avoir renseigné ses informations, l’utilisateur se voit doté d’un code à
                      5 chiffres (pouvant également contenir les lettres A ou B) qui lui permettent
                      alors d’accéder au service de dépôt de ses déchets alimentaires.
                    </li>
                    <li>
                      Ce code usager est unique pour chaque utilisateur. Celui-ci n’a le droit de
                      l’utiliser que pour les déchets alimentaires de son foyer ; Il n’est pas
                      autorisé à divulguer ce code à d’autres usagers.
                    </li>
                  </ul>
                </li>
                <li>
                  Des opérateurs de mon quartier collectent les PAV pour remplir un composteur
                  <br />
                  Je m'engage donc à respecter consignes de tri qui me seront transmises par mail
                  pour leur faciliter la vie.
                  <ul className="list-circle">
                    <li>
                      En complément, les présentes conditions générales d’utilisation impliquent
                      également que l’utilisateur s’engage à ne pas dégrader le matériel qui lui est
                      mis à disposition de quelque manière que ce soit.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                En finalisant l’inscription, l’utilisateur est inscrit sur le serveur de
                l’application. Les données liées à son compte ne sont consultables que dans le cadre
                des activités de compostage de quartier menées par UPCYCLE, le commanditaire du
                contrat, ou ses partenaires.
              </p>
              <div className="text-center">
                <img src={require(`../../assets/images/consignes-de-tri-upcycle.jpg`)} />
              </div>
              <h3>3. Création d’un compte Utilisateur ou d’un compte Client</h3>
              <p>
                L’Utilisateur n’est pas tenu de s’inscrire pour avoir accès à certains Services du
                Site. Néanmoins, lorsqu’il souhaite bénéficier des Services qui requièrent une telle
                inscription, les stipulations suivantes sont applicables.
              </p>
              <p>
                <strong>Création d’un compte</strong>
                <br />
                <br />
                Lors de son inscription, l’Utilisateur s’engage à fournir des informations exactes
                et sincères. En outre, l’inscription est exclusivement personnelle ; elle ne peut
                être ouverte pour le compte d’un tiers, et est incessible.
                <br />
                <br />
                L’Identifiant, choisi par l’Utilisateur, ne doit pas porter atteinte, de quelque
                manière que ce soit, aux droits des tiers de toutes natures, ni reproduire des
                marques, ni constituer une menace ou une incitation à la violence, ni revêtir un
                caractère diffamatoire, obscène, discriminatoire, sexuellement explicite ou
                contraire à l’ordre public ou à la législation et à la réglementation françaises et
                communautaires en vigueur.
                <br />
                <br />
                La création d’un compte requiert de communiquer, sur le Site :
              </p>
              <ul>
                <li>une adresse de courriel valide</li>
                <li>un nom</li>
                <li>un prénom</li>
                <li>
                  une adresse permettant la localisation du point d’apport volontaire le plus proche
                </li>
                <li>le nombre de personnes présentes dans votre foyer</li>
              </ul>
              <p>
                L’Éditeur se réserve le droit de refuser l’inscription d’un Utilisateur ou d’un
                Client dont les informations se révèlent inexactes, incomplètes ou non conformes à
                la procédure décrite ci-dessus et aux présentes Conditions Générales d’Utilisation.
                <br />
                <br />
                Le clic de validation confirme la création du compte et vaut acceptation des
                présentes Conditions Générales d’Utilisation. La validation est confirmée par la
                notification d’un courriel de confirmation de l’Utilisateur et vaut acceptation de
                l’offre des services réservés aux Utilisateurs.
                <br />
                <br />
                <strong>Identifiant et code usager</strong>
                <br />
                <br />
                Le code usager est confidentiel. L’Utilisateur est responsable de ses éléments
                d’identification auprès des tiers.
                <br />
                <br />
                Au cas où l’Utilisateur aurait perdu son code usager, il peut le récupérer en
                indiquant son adresse mail dans la section « J’ai oublié mon code ou mon PAV »
                <br />
                <br />
                L’Utilisateur peut également utiliser cette section pour retrouver l’emplacement du
                PAV qu’il avait choisi lors de son inscription.
                <br />
                <br />
                L’Éditeur ne peut être tenu responsable de tous dommages directs ou indirects subis
                par l’Utilisateur ou par les tiers, résultant d’un accès frauduleux au compte de
                l’Utilisateur, à la suite d’une divulgation des éléments d’identification de
                celui-ci.
                <br />
                <br />
                <strong>Durée et résiliation</strong>
                <br />
                <br />
                Pour l’Utilisateur qui a créé un compte :
                <br />
                <br />
                L’inscription de l’Utilisateur sur le Site d‘UPCYCLE est conclue pour une durée
                indéterminée. L’Utilisateur peut y mettre fin, à tout moment, en notifiant sa
                volonté par courriel ou téléphone aux partenaires locaux impliqués dans le
                compostage des déchets alimentaires.
                <br />
                <br />
                L’Éditeur se réserve le droit de refuser ou de supprimer le compte de l’Utilisateur,
                sans préavis, sans qu’il puisse s’y opposer ou prétendre à une quelconque indemnité,
                en cas de manquement aux obligations résultant des présentes CGU, ou en cas de
                violation par l’Utilisateur des lois et réglementations diverses en vigueur, de
                portée nationale, communautaire et internationale le cas échéant, ainsi qu’à l’ordre
                public et aux bonnes mœurs.
                <br />
                <br />
              </p>
              <h3>4. Propriété intellectuelle</h3>
              <p>
                Toutes les Informations contenues sur le Site et les Services, tel que les textes,
                illustrations et images, clips audio et vidéo, marques, etc., sont la propriété
                exclusive de l’Éditeur et sont protégées par le droit de la propriété intellectuelle
                en droit français.
                <br />
                <br />
                L’Utilisateur accepte de n’utiliser ces Informations ou éléments que dans le cadre
                d’une utilisation conforme aux présentes CGU.
                <br />
                <br />
                Toute reproduction, diffusion, représentation des données protégées, sans
                autorisation écrite préalable d’UPCYCLE, est constitutive d’une contrefaçon et
                expose à des poursuites civile et pénale.
                <br />
                <br />
              </p>
              <h3>5. Protection des données personnelles</h3>
              <p>
                En acceptant les présentes Conditions Générales d’Utilisation, l’Utilisateur
                reconnaît avoir pris connaissance de la Politique de Protection des Données
                Personnelles.
                <br />
                <br />
                L’Utilisateur accepte de n’utiliser ces Informations ou éléments que dans le cadre
                d’une utilisation conforme aux présentes CGU.
                <br />
                <br />
                Les données fournies par l’Utilisateur, lors de son inscription, sont des Données
                Personnelles dont la collecte et le traitement sont régis par notre Politique de
                Protection des Données Personnelles :
                <ul className="lower-latin">
                  <li>
                    Objet et champ d’application de la Politique de protection des données
                    personnelles
                    <p>
                      La présente Politique vous expose les modalités et caractéristiques des
                      traitements de données mis en œuvre par UPCYCLE. Elle vous informe sur les
                      modalités de traitement, dont la collecte et l’utilisation de vos Données
                      Personnelles, ainsi que les droits qui vous sont conférés.
                    </p>
                  </li>
                  <li>
                    Utilisateurs mineurs
                    <p>
                      Si vous êtes mineur, nous vous invitons à prendre connaissance de la présente
                      Politique avec vos parents ou représentants légaux, et lire attentivement avec
                      eux les CGU. L’adresse mail mentionnée lors de l’inscription de l’utilisateur
                      doit être celle d’une personne majeure présente dans le foyer.
                    </p>
                  </li>
                  <li>
                    Principes relatifs au traitement de données personnelles
                    <p>
                      UPCYCLE s’engage à respecter les dispositions françaises et européennes
                      relatives à la protection des Données Personnelles, à l’occasion de tout
                      traitement de Données Personnelles.
                      <br />
                      <br />
                      UPCYCLE s’engage à collecter et à traiter les Données Personnelles de manière
                      loyale, transparente, licite et documentée.
                      <br />
                      <br />
                      UPCYCLE s’assure notamment que les Données Personnelles ont été collectées
                      pour des finalités déterminées, explicites et légitimes, et qu’elles ne seront
                      pas traitées ultérieurement d’une manière incompatible avec ces finalités.
                      <br />
                      <br />
                      UPCYCLE s’engage à respecter le principe de minimisation des données en
                      veillant à ce que les Données Personnelles collectées soient adéquates,
                      pertinentes et limitées à ce qui est nécessaire au regard des finalités pour
                      lesquelles elles sont traitées.
                      <br />
                      <br />
                      UPCYCLE s’engage à ce que les personnes concernées aient été informées du
                      traitement de leurs Données Personnelles et que leur consentement ait été
                      recherché et obtenu pour chaque finalité donnée, dès lors qu’un tel
                      consentement est requis par la loi.
                    </p>
                  </li>
                  <li>
                    Le traitement des données personnelles
                    <p>
                      <strong>Les Données Personnelles traitées</strong>
                      <br />
                      Plusieurs catégories de Données Personnelles peuvent être traitées par UPCYCLE
                      sur le Site ou via les formulaires « papier », ce traitement pouvant varier
                      selon les supports :
                      <ul>
                        <li>
                          Les Données Personnelles permettant votre identification : votre courriel,
                          nom et prénom, vos coordonnées personnelles (adresse pour le choix du
                          PAV).
                        </li>
                        <li>
                          Les données de connexion (logs de connexion et d’usage, adresse IP, utm
                          source lié à vos conditions d’inscription).
                        </li>
                        <li>
                          Les données relatives à l’ouverture des courriels (lettres d’information,
                          …) et aux clics sur les liens contenus dans ces courriels.
                        </li>
                        <li>
                          Les Les données nécessaires à la réalisation des actions de fidélisation,
                          de prospection, d’étude, de sondage sur le Site.
                        </li>
                        <li>Données de suivi de vos demandes (demande de renseignements, etc.).</li>
                      </ul>
                      UPCYCLE s’engage à ne pas traiter, sur son Site, des Données Personnelles
                      dites sensibles (origines raciales ou ethniques, opinions politiques,
                      philosophiques ou religieuses, appartenance syndicale, état de santé, données
                      génétiques et biométriques) ou liées à des condamnations et infractions
                      pénales. Le Site d’UPCYCLE n’a pas vocation à contenir de telles données.
                      UPCYCLE rappelle aux Utilisateurs du Site l’interdiction de saisir ces Données
                      Personnelles sur le Site. Les Utilisateurs engagent leur responsabilité à
                      l’égard des personnes physiques concernées en cas de saisie volontaire ou
                      involontaire de Données Personnelles par ces derniers.
                      <br />
                      <br />
                      <strong>L’origine de la collecte des Données Personnelles</strong>
                      <br />
                      Les Données Personnelles sont traitées directement auprès de vous, notamment
                      lorsque vous remplissez des formulaires ou lors de la création d’un compte sur
                      le Site et ses Services.
                    </p>
                  </li>
                  <li>
                    Le caractère obligatoire ou non du traitement
                    <p>
                      Pour chaque collecte, vous êtes informé, sur le formulaire de collecte, du
                      caractère obligatoire des réponses par la présence d’un astérisque (*) à côté
                      du (des) champ(s) concerné(s). À défaut de renseignement des informations
                      ayant un caractère obligatoire, la demande liée à cette collecte (par exemple,
                      l’envoi postal du kit de tri, ou d’autres éléments d’information) pourrait ne
                      pas pouvoir être traitée ou son traitement retardé.
                    </p>
                  </li>
                  <li>
                    Les finalités du traitement des Données Personnelles et leur base légale
                    <p>
                      Nous collectons uniquement des Données lorsque nous disposons d’un fondement
                      légal pour le faire.
                      <br />
                      <br />
                      Nos traitements sont fondés sur l’une des bases légales suivantes :
                      <ul>
                        <li>votre consentement ou</li>
                        <li>
                          l’exécution des contrats que vous avez souscrits auprès de nous (compte
                          utilisateur,), et/ou ;
                        </li>
                        <li>notre intérêt légitime</li>
                        <li>nos obligations légales</li>
                      </ul>
                      Lorsque le traitement est fondé sur le consentement, si vous retirez votre
                      consentement, votre décision n’affectera pas notre droit de traiter vos
                      données quand un tel traitement est nécessaire pour exécuter nos obligations
                      contractuelles à votre égard et/ou dans la mesure où nous sommes obligés de
                      réaliser ce traitement de par la loi ou pour pouvoir défendre nos intérêts
                      dans le cadre d’un contentieux, se prémunir contre la fraude ou les abus ou
                      pour faire appliquer nos Conditions Générales.
                      <br />
                      <br />
                      Les Clients et Utilisateurs sont informés de la (des) finalité(s) du
                      traitement de leurs Données Personnelles lors de la collecte.
                      <br />
                      <br />
                      Les Données Personnelles sont traitées pour les finalités suivantes :
                      <br />
                      <br />
                      <u>Traitements opérés sur le fondement légal de votre consentement</u>
                      <ul>
                        <li>
                          Envoi d’informations ciblées par courriel et sur les réseaux sociaux (le
                          cas échéant) ;
                        </li>
                      </ul>
                      <u>
                        Traitements opérés sur le fondement de l’intérêt légitime poursuivi par
                        UPCYCLE
                      </u>
                      <ul>
                        <li>
                          Traitement et suivi de vos demandes et de vos échanges avec UPCYCLE
                          (demande de documentation, de participation à un événement…)
                        </li>
                        <li>
                          Envoi d’enquêtes de satisfaction à des fins d’amélioration de nos offres
                          et services ;
                        </li>
                        <li>
                          Réalisation et élaboration d’études, d’analyses, de reporting et de
                          statistiques à des fins de gestion et d’amélioration de nos services ;
                        </li>
                        <li>
                          Gestion des réseaux sociaux à des fins de communication sur des évènements
                          liés à l’activité de compostage de votre quartier ;
                        </li>
                      </ul>
                      <u>
                        Traitements opérés sur le fondement des mesures précontractuelles et
                        contractuelles :
                      </u>
                      <ul>
                        <li>
                          Ouverture et gestion de votre compte (mise à disposition d’un espace
                          réservé, etc.) et enregistrement de vos comptes en ligne ;
                        </li>
                        <li>
                          Gestion des actions de sensibilisation, de formation et d’information
                          relatives aux règles de compostage des déchets permettant la bonne
                          utilisation des PAV
                        </li>
                        <li>
                          Production de reporting permettant de suivre l’utilisation des PAV à
                          destination de notre partenaire (collectivité ou entreprise contractante).
                        </li>
                      </ul>
                    </p>
                  </li>
                  <li>
                    Les destinataires du traitement des Données Personnelles
                    <p>
                      Vos Données Personnelles sont destinées aux services et personnels habilités
                      d’UPCYCLE pour la réalisation de ses finalités de traitement définies
                      précédemment (service client, service Internet, service financier, service
                      marketing, service juridique…).
                      <br />
                      <br />
                      Elles peuvent être communiquées, pour certaines des finalités précitées, et ce
                      uniquement si une telle communication s’avère nécessaire, à nos prestataires
                      pouvant intervenir dans le cadre des traitements de Données Personnelles.
                      <br />
                      <br />
                      Ces destinataires reçoivent uniquement les Données Personnelles nécessaires à
                      la finalité impliquant une telle communication. Il s’agit des prestataires
                      suivants :
                      <ul>
                        <li>
                          Nos sous-traitants techniques (hébergement, supervision informatique…) ;
                        </li>
                        <li>
                          Nos sous-traitants marketing (opérations de prospection, gestion des
                          jeux-concours…) ;
                        </li>
                      </ul>
                      Les données peuvent être également communiquées à d’autres destinataires :
                      <ol className="list-decimal">
                        <li>
                          si UPCYCLE est contraint de le faire (exemple : sur réquisition en
                          provenance des autorités judiciaires, policières ou administratives) ou
                          directement à ces autorités, ou
                        </li>
                        <li>
                          si le partage est licite en vertu de la loi applicable (à certaines
                          professions réglementées telles que, de manière non exhaustive, les
                          avocats, notaires, commissaires aux comptes dans le cadre de l’exécution
                          de leur mandat), ou
                        </li>
                        <li>
                          si le tiers dispose d’un intérêt légitime (lutte contre la fraude par
                          exemple).
                        </li>
                      </ol>
                    </p>
                  </li>
                  <li>
                    Transferts internationaux de Données Personnelles
                    <p>
                      Les Données Personnelles traitées par UPCYCLE ne font pas l’objet d’un
                      transfert en dehors de l’Union européenne. Néanmoins, dans l’hypothèqe où
                      Upcycle devrait procéder à de tel transfert UPCYCLE s’assurera que le pays
                      tiers dispose d’un niveau de protection jugé adéquat par la Commission
                      européenne.
                      <br />
                      <br />
                      En conséquence, si vos données personnelles étaient traitées dans des pays qui
                      ne sont pas reconnus comme offrant un niveau de protection adéquat des données
                      personnelles conformément au Règlement (UE) 2016/679 du Parlement européen et
                      du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à
                      l’égard du traitement des données à caractère personnel et à la libre
                      circulation de ces données, alors, UPCYCLE s’attachera à ce que ses
                      prestataires se soient engagés à respecter les garanties conformes à la
                      réglementation en vigueur pour de tels transferts (exemple : clauses
                      contractuelles types de la Commission européenne, Règles contraignantes
                      d’entreprise (BCR),).
                    </p>
                  </li>
                  <li>
                    Durée de conservation des Données Personnelles
                    <p>
                      UPCYCLE s’engage à conserver les Données Personnelles pour une durée limitée
                      et proportionnée qui n’excède pas celle nécessaire aux finalités pour
                      lesquelles elles sont traitées.
                      <br />
                      <br />
                      Les Données Personnelles sont conservées pendant la période nécessaire à
                      l’accomplissement des finalités mentionnées ci-dessous, notamment afin de
                      répondre aux obligations légales.
                      <ul>
                        <li>
                          Les données de connexion, de navigation et de trafic sont conservées
                          pendant une durée maximale d’un an, conformément aux obligations légales ;
                        </li>
                        <li>
                          Les données concernant votre inscription à votre PAV ou une ou plusieurs
                          lettres d’information sont conservées pendant la durée de votre
                          inscription ;
                        </li>
                      </ul>
                      Il est précisé que l’ensemble de ces Données Personnelles peuvent toutefois
                      être conservées plus longtemps que les durées précitées : soit après obtention
                      de votre accord ; soit sous forme d’archives ; soit pour répondre aux
                      obligations légales et réglementaires éventuelles qui s’imposent à UPCYCLE ou
                      encore pendant les délais légaux de prescription ou d’opposition.
                    </p>
                  </li>
                  <li>
                    Quels sont vos droits sur vos Données Personnelles et comment les exercer ?
                    <p>
                      L’exercice de vos droits sur les Données Personnelles
                      <br />
                      Vous disposez d’un droit d’accès, de rectification, de limitation, de
                      portabilité, d’opposition, d’effacement de vos Données Personnelles. Vous avez
                      également la possibilité de définir vos directives après la mort sur le sort
                      de vos données (dites « directives post-mortem »), dans les conditions
                      légales.
                      <br />
                      <br />
                      Concernant l’actualisation des Données Personnelles, nous vous invitons à
                      signaler toute modification de vos informations, principalement votre adresse
                      postale ou de courriel, pour nous permettre une bonne exécution de vos
                      demandes. Le contact privilégié est celui indiqué sur votre PAV ou les
                      supports de communication qui vous ont été transmis.
                      <br />
                      <br />
                      Vous pouvez exercer vos droits :
                      <br />
                      <br />
                      Par courriel, à l’adresse suivante :
                      <a href="mailto:sav@upcycle.org">sav@upcycle.org</a> Upcycle s’engage à
                      traiter votre demande dans les 30 jours calendaires suite à la réception de
                      celle-ci.
                      <br />
                      <br />
                      Pour exercer vos droits, nous pouvons vous demander de justifier votre
                      identité.
                      <br />
                      <br />
                      Notez que, dans certains cas, vous pouvez exercer les droits directement en
                      ligne :
                      <br />
                      <br />
                      Si vous êtes abonné à une lettre d’information, vous pouvez à tout moment vous
                      désabonner en cliquant sur le lien de désabonnement situé au bas de la lettre.
                      <br />
                      <br />
                      Dans l’hypothèse ou vous estimez que vos droits ne sont pas respectez, vous
                      avez la possibilité de vous adresser à la Commission Nationale de
                      l’Informatique et des Libertés (CNIL) par internet https://www.cnil.fr/fr/agir
                      ou par voie postale à l’adresse suivante : Commission Nationale de
                      l'Informatique et des Libertés, 3 Place de Fontenoy - TSA 80715, 75334 PARIS
                      CEDEX.
                    </p>
                  </li>
                  <li>
                    La sécurisation des données
                    <p>
                      Le respect de la sécurité des données est une priorité de UPCYCLE, respect
                      assuré par la mise en œuvre des mesures techniques permettant de protéger vos
                      Données Personnelles de façon appropriée.
                      <br />
                      <br />
                      UPCYCLE s’engage à mettre en œuvre tous les moyens nécessaires en vue de
                      garantir la sécurité et la confidentialité des Données Personnelles traitées,
                      et à prendre toutes les mesures techniques et organisationnelles appropriées
                      afin de restreindre les risques de perte, de déformation, de détérioration ou
                      de mauvaise utilisation de celles-ci, ainsi que les risques d’accès aux
                      Données Personnelles par des tiers non autorisés.
                      <br />
                      <br />
                      En cas de faille de sécurité pouvant constituer un risque pour vos droits et
                      libertés, UPCYCLE s’engage à alerter l’autorité de contrôle dans les 72 heures
                      après en avoir pris connaissance, ainsi que les personnes concernées dans les
                      conditions prévues par la réglementation.
                      <br />
                      <br />
                      Cette obligation s’impose à l’ensemble des collaborateurs de UPCYCLE et aux
                      prestataires.
                    </p>
                  </li>
                </ul>
              </p>
              <h3>6. Responsabilité</h3>
              <p>
                L’Éditeur ne garantit pas la fiabilité, la performance, l’exhaustivité des contenus
                édités sur le Site avec l’utilisation que souhaitent en faire les Utilisateurs. Ces
                derniers acceptent d’utiliser ces informations sous leur responsabilité exclusive.
                <br />
                <br />
                L’Éditeur ne peut donc être tenu pour responsable des dommages directs ou indirects
                dont se prévaudraient les Utilisateurs, qui se fondent sur l’information ou contenu
                édité sur le Site et les Services.
                <br />
                <br />
                L’Éditeur est soumis à une obligation de moyens envers les Utilisateurs, dans la
                fourniture du Site et des Services et leur fonctionnement. L’Éditeur se réserve
                néanmoins la possibilité d’effectuer des interventions techniques, de maintenance ou
                d’actualisation sur le Site et les Services, occasionnant une interruption
                temporaire d’accès.
                <br />
                <br />
                Dans tous les cas, les dysfonctionnements et indisponibilités de services qui en
                résultent ne peuvent donner lieu à un quelconque dédommagement pour l’Utilisateur ou
                le Client.
                <br />
                <br />
                L’Éditeur s’engage à répondre dans les meilleurs délais aux demandes et réclamations
                des Utilisateurs, demandes de retrait ou de modification des contenus publiés sur le
                Site et les Services, dans les conditions prévues par les présentes CGU et en
                conformité avec les dispositions légales applicables issues de la loi pour la
                confiance dans l’économie numérique (LCEN).
                <br />
                <br />
                Aussi, l’Éditeur n’est en aucun cas responsable des dommages directs et indirects,
                subis par les Utilisateurs, des Informations et contenus édités sur le Site.
                <br />
                <br />
                L’Éditeur décline toute responsabilité quant au téléchargement et à l’installation
                des applications qui, elles, sont soumises aux conditions générales d’utilisation de
                Apple Store et Google Play.
              </p>
              <h3>7. Droit applicable – Litige</h3>
              <p>
                Les présentes Conditions Générales d’Utilisation sont soumises au droit français.
                Tout litige intervenant à l’occasion de l’exécution du Contrat ou à l’occasion de
                l’interprétation de ses dispositions fera l’objet d’une recherche d’un accord
                amiable. À défaut, tout litige relèvera de la compétence exclusive des tribunaux
                français compétent
              </p>
            </div>
          )}
          <div className="modal-footer">
            <div className="btn-group">
              <button onClick={() => closeModalCGU()} className="btn btn-secondary">
                <span>Fermer</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
