import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { find, reverse, isEmpty } from 'lodash';
import Autosuggest from 'react-autosuggest';

import useDebouncedSearch from '../../utils/useDebouncedSearch';

const searchPosition = adresse => {
  //console.log('Appel API', Date.now(), adresse);
  return axios
    .get(`https://api-adresse.data.gouv.fr/search/?q=${adresse}`)
    .then(results => {
      return results.data.features;
    })
    .catch(error => {
      console.log('error lors du get data.gouv', error);
    });
};

const useSearch = () => useDebouncedSearch(adresse => searchPosition(adresse));

const InputSearchAddress = ({ onChangePositionProps, onChangeInputProps, positionSelected }) => {
  const [suggestionsAddress, setSuggestionsAddress] = useState([]);

  const [value, setValue] = useState('');

  const { inputText, setInputText, searchResults } = useSearch();

  useEffect(() => {
    if (isEmpty(value)) {
      onChangeInputProps('');
      onChangePositionProps(null);
    }
  }, [value]);

  useEffect(() => {
    if (searchResults && searchResults.result && searchResults.result.length) {
      setSuggestionsAddress(searchResults.result.map(d => d.properties.label));
    }
    return () => {
      // console.log('DEBUG unmount');
    };
  }, [searchResults.result]);

  const changePosition = adresse => {
    const adresseSelected = find(
      searchResults.result,
      result => result.properties.label === adresse
    );

    if (adresseSelected) {
      if (onChangePositionProps) {
        onChangePositionProps(reverse(adresseSelected.geometry.coordinates));
      }

      if (onChangeInputProps) {
        onChangeInputProps(adresseSelected.properties.label);
      }
    }
  };
  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (
      (newValue.length > 0) &
      (searchResults && searchResults.result && searchResults.result.length > 0)
    ) {
      changePosition(newValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength < 2) {
      return [];
    }
    setInputText(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestionsAddress([]);
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => {
    return <div>{suggestion}</div>;
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Adresse',
    value,
    onChange
  };

  return (
    <Autosuggest
      suggestions={suggestionsAddress}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};

export default InputSearchAddress;
