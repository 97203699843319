import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { loadPavs } from '../store/modules/pavs';
import { loadAppHabitants } from '../store/modules/appHabitants';
import { setUtmsource } from '../store/modules/auth';
import BaseLayout from './shared/BaseLayout';
import Loading from './Loading';
import { API_URL } from '../constants/Config';
import logo from '../../assets/images/logo-upcycle.svg';

const HomePage = ({
  account,
  loadPavs,
  appHabitants,
  slug,
  setUtmsource,
  loadAppHabitants,
  loadingAppHabitants
}) => {
  const { search } = useLocation();
  const { utm_source } = queryString.parse(search);

  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
    loadPavs();
  }, []);

  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (utm_source) {
      setTimeout(() => setUtmsource(utm_source), 500);
    }
  }, []);
  return loadingAppHabitants ? (
    <Loading />
  ) : (
    <BaseLayout title="home" appHabitants={appHabitants}>
      <div className="container">
        <div className="center">
          <img
            className="banane"
            src={`${API_URL}/uploads/${
              appHabitants && appHabitants.fileName
                ? appHabitants.fileName
                : 'upcycle-home-picture.png'
            }`}
          />

          <h1>
            {appHabitants && appHabitants.sousTitre
              ? appHabitants.sousTitre
              : 'Participez au compostage de vos déchets alimentaires dans votre quartier !'}
          </h1>
        </div>
        <div className="home-box">
          <div className="grid">
            <div className="col-12">
              <NavLink
                to={(appHabitants && appHabitants.slug ? appHabitants.slug : '') + '/faq'}
                className="btn btn-primary btn-big"
              >
                <i className="fas fa-info-circle" />
                <span>
                  {appHabitants && appHabitants.informationLibelle
                    ? appHabitants.informationLibelle
                    : 'Comment ça marche ?'}
                </span>
              </NavLink>
            </div>
            <div className="col-12">
              <NavLink
                to={(appHabitants && appHabitants.slug ? appHabitants.slug : '') + '/adhesion'}
                className="btn btn-primary btn-big"
              >
                <i className="fas fa-file-signature" />
                <span>
                  {appHabitants && appHabitants.participationTitre
                    ? appHabitants.participationTitre
                    : "J'adhère pour composter !"}
                </span>
              </NavLink>
            </div>
            <div className="col-12">
              <NavLink
                to={
                  account && account.user && account.user.code
                    ? (appHabitants && appHabitants.slug ? appHabitants.slug : '') + '/mon-code-pav'
                    : (appHabitants && appHabitants.slug ? appHabitants.slug : '') +
                      '/password-not-found'
                }
                className="btn btn-primary btn-big"
              >
                <i className="fas fa-dumpster" />
                <span>
                  {appHabitants && appHabitants.oublieButton
                    ? appHabitants.oublieButton
                    : "J'ai oublié mon code / où est mon PAV ?"}
                </span>
              </NavLink>
            </div>
            <div className="col-12">
              <NavLink
                to={(appHabitants && appHabitants.slug ? appHabitants.slug : '') + '/signalement'}
                className="btn btn-primary btn-big"
              >
                <i className="fas fa-megaphone">
                  <span></span>
                  <span></span>
                  <span></span>
                </i>
                <span>
                  {appHabitants && appHabitants.signalerButton
                    ? appHabitants.signalerButton
                    : 'Signaler un problème !'}
                </span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="version">
          <img className="logo-version" src={logo} />
          <span>version {`${process.env.VERSION}`}</span>
        </div>
      </div>
      <div className="background">
        <div className="background-overlay"></div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = (state, props) => {
  let slug = props.match.params.slug || null;
  return {
    account: state.auth,
    slug: slug,
    pavs: state.data.entities.pavs,
    appHabitants:
      slug && state.data && state.data.entities && state.data.entities.appHabitants
        ? state.data.entities.appHabitants[slug]
        : {},
    loadingAppHabitants: state.data.loading.appHabitants
  };
};

export default connect(mapStateToProps, {
  loadPavs,
  setUtmsource,
  loadAppHabitants
})(HomePage);
