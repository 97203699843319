export default {
  entities: {
    accounts: {},
    appHabitants: {},
    pavs: {}
  },
  loaded: {
    appstorage: false,
    accounts: false,
    appHabitants: false,
    pavs: false
  },
  loading: {
    accounts: false,
    appHabitants: false,
    pavs: false
  }
};
