import { accountsActionsHandlers } from './accounts';
import { appHabitantsActionsHandlers } from './appHabitants';
import { pavsActionsHandlers } from './pavs';

import initialState from '../initialState';
import '../../index';


const actionsHandlers = {
  ...accountsActionsHandlers,
  ...appHabitantsActionsHandlers,
  ...pavsActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
