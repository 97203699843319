import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { find, filter } from 'lodash';
import { NavLink, useParams } from 'react-router-dom';

import BaseLayout from './shared/BaseLayout';

import { loadAppHabitants } from '../store/modules/appHabitants';
import MapPavSelector from '../components/shared/MapPavSelector';

const MyCodePavPage = ({ account, pavs, appHabitants, loadAppHabitants }) => {
  const { slug } = useParams();
  const pavAssocieUser =
    account.user && find(pavs, pav => pav && pav.pav_id === account.user.pav_id);

  const [positionSelected, setPositionSelected] = useState(
    (pavAssocieUser && [pavAssocieUser.latitude, pavAssocieUser.longitude]) || [
      48.1107924,
      -1.6677123
    ]
  );
  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);
  return (
    <BaseLayout appHabitants={appHabitants}>
      <header>
        <NavLink to={'/' + (slug ? slug : '')} className="link">
          <i className="far fa-times"></i>
          <span>Annuler</span>
        </NavLink>
        <h2>{appHabitants?.oublieTitle ? appHabitants.oublieTitle : `J'ai oublié mon code PAV`}</h2>
      </header>
      <main>
        <div className="container">
          <div className="mycode-step-message">
            <h2>{appHabitants?.oublie2 ? appHabitants.oublie2 : `Code d'accès à votre PAV`}</h2>
            <div className="highlight-value">
              {account && account.user && account.user.code}
              <img className="btn-enter" src={require(`../../assets/images/bouton-enter.png`)} />
            </div>
          </div>
          <div className="map">
            <MapPavSelector
              notSearch
              notSelectPav
              pavSelected={pavAssocieUser}
              positionSelected={positionSelected}
            />
          </div>

          <div className="btn-group flexColumn center">
            <NavLink to={'/' + (slug ? slug : '')} className="btn btn-quaternary">
              <span>Fermer</span>
            </NavLink>
          </div>
        </div>
      </main>
    </BaseLayout>
  );
};

MyCodePavPage.propTypes = {
  account: PropTypes.object.isRequired,
  pavs: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  // let slug = props.slug || null;
  let slug = props.match.params.slug || null;

  return {
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {},
    account: state.auth,
    pavs: state.data.entities.pavs
  };
};

export default connect(mapStateToProps, {loadAppHabitants})(MyCodePavPage);
