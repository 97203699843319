import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { filter } from 'lodash';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { recoverCodePAV } from '../store/modules/auth';
import { loadAppHabitants } from '../store/modules/appHabitants';

import BaseLayout from './shared/BaseLayout';
import { validateEmail } from '../utils/StringValidator';

const PasswordOubliePage = ({ recoverCodePAV, appHabitants, loadAppHabitants }) => {
  const { slug } = useParams();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  let history = useHistory();

  const submitRecover = () => {
    if (validateEmail(email)) {
      return recoverCodePAV(email)
        .then(res => {
          history.push('mon-code-pav');
        })
        .catch(err => {
          setError(
            "Une erreur s'est produite lors de la récupération de votre code PAV ! : L'adresse email est inconnue"
          );
        });
    } else {
      setError(
        "Une erreur s'est produite lors de la récupération de votre code PAV ! : L'adresse email est invalide"
      );
    }
  };

  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);

  return (
    <BaseLayout appHabitants={appHabitants}>
      <header>
        <NavLink to={'/' + (slug ? slug : '')} className="link">
          <i className="far fa-times"></i>
          <span>Annuler</span>
        </NavLink>
        <h2>{appHabitants?.oublieTitle ? appHabitants.oublieTitle : `J'ai oublié mon code PAV`}</h2>
      </header>
      <main>
        <div className="container">
          <div className="form-small">
            <div className="field text-center">
              <h3>Saisissez votre adresse email</h3>
              <input
                onChange={e => {
                  setEmail(e.target.value);
                  setError(null);
                }}
                type="email"
              />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="btn-group flexColumn center">
              <button onClick={() => submitRecover()} disabled={!email} className="btn">
                Récupérer mon code
              </button>
            </div>
          </div>
        </div>
      </main>
    </BaseLayout>
  );
};

PasswordOubliePage.propTypes = {
  recoverCodePAV: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  // let slug = props.slug || null;
  let slug = props.match.params.slug || null;
  return {
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {},
    account: state.auth,
    pavs: state.data.entities.pavs
  };
};
export default connect(mapStateToProps, { recoverCodePAV, loadAppHabitants })(PasswordOubliePage);
