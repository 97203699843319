import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'react-modal';

Modal.setAppElement('body');

const BaseLayout = ({ children, title, appHabitants }) => {
  useEffect(() => {
    const root = document.body;
    if (appHabitants && appHabitants.primaryColor && appHabitants.primaryColorRGB) {
      root?.style.setProperty('--firstColor', appHabitants.primaryColor);
      root?.style.setProperty('--firstColorR', appHabitants.primaryColorRGB.r);
      root?.style.setProperty('--firstColorG', appHabitants.primaryColorRGB.g);
      root?.style.setProperty('--firstColorB', appHabitants.primaryColorRGB.b);
    } else {
      root?.style.setProperty('--firstColor', '#c83257');
      root?.style.setProperty('--firstColorR', '200');
      root?.style.setProperty('--firstColorG', '50');
      root?.style.setProperty('--firstColorB', '87');
    }
    if (appHabitants && appHabitants.secondaryColor && appHabitants.secondaryColorRGB) {
      root?.style.setProperty('--secondColor', appHabitants.secondaryColor);
      root?.style.setProperty('--secondColorR', appHabitants.secondaryColorRGB.r);
      root?.style.setProperty('--secondColorG', appHabitants.secondaryColorRGB.g);
      root?.style.setProperty('--secondColorB', appHabitants.secondaryColorRGB.b);
    } else {
      root?.style.setProperty('--secondColor', '#3f192a');
      root?.style.setProperty('--secondColorR', '63');
      root?.style.setProperty('--secondColorG', '25');
      root?.style.setProperty('--secondColorB', '42');
    }
    // root?.style.setProperty("--text-color", darkTheme ? "#fff" : "#262833");
  }, []);
  useEffect(() => {
    const root = document.body;
    if (appHabitants && appHabitants.primaryColor && appHabitants.primaryColorRGB) {
      root?.style.setProperty('--firstColor', appHabitants.primaryColor);
      root?.style.setProperty('--firstColorR', appHabitants.primaryColorRGB.r);
      root?.style.setProperty('--firstColorG', appHabitants.primaryColorRGB.g);
      root?.style.setProperty('--firstColorB', appHabitants.primaryColorRGB.b);
    }
    if (appHabitants && appHabitants.secondaryColor && appHabitants.secondaryColorRGB) {
      root?.style.setProperty('--secondColor', appHabitants.secondaryColor);
      root?.style.setProperty('--secondColorR', appHabitants.secondaryColorRGB.r);
      root?.style.setProperty('--secondColorG', appHabitants.secondaryColorRGB.g);
      root?.style.setProperty('--secondColorB', appHabitants.secondaryColorRGB.b);
    }
    // root?.style.setProperty("--text-color", darkTheme ? "#fff" : "#262833");
  }, [appHabitants]);
  return (
    <div id="wrapper" className={`front ${title}`}>
      {children}
    </div>
  );
};
//
BaseLayout.propTypes = {
  children: PropTypes.node
};

export default connect(state => ({
  account: state.auth.user
}))(BaseLayout);
