import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { map } from 'lodash';
import { closest } from 'leaflet-geometryutil';
import InputSearchAddressGoogle from './InputSearchAddressGoogle';
import CardPavSelected from './CardPavSelected';
import { loadPavs } from '../../store/modules/pavs';
import { getDistance } from '../../utils/DistanceUtils';

const MapPavSelector = ({
  paysCode,
  notSearch,
  notSelectPav,
  pavs,
  loadPavs,
  selectPav,
  pavSelected,
  positionSelected,
  onChangePositionFunc,
  isSearchPositionProche
}) => {
  const [mapLeaflet, setMapLeaflet] = useState(null);
  const [initPosition, setInitPosition] = useState(positionSelected || [48.1107924, -1.6677123]);

  const searchPositionProche = () => {
    if (mapLeaflet && positionSelected) {
      const positionPlusProche = closest(
        mapLeaflet,
        map(pavs, p => [p && p.latitude, p && p.longitude]),
        (positionSelected.lat &&
          positionSelected.lng && [positionSelected.lat, positionSelected.lng]) ||
          positionSelected,
        true
      );

      const distancePavProche = getDistance(
        [positionPlusProche.lat, positionPlusProche.lng],
        (positionSelected.lat &&
          positionSelected.lng && [positionSelected.lat, positionSelected.lng]) ||
          positionSelected
      );

      if (distancePavProche <= 5) {
        mapLeaflet.flyTo([positionPlusProche.lat, positionPlusProche.lng], mapLeaflet.getZoom());
      } else {
        mapLeaflet &&
          mapLeaflet.flyTo(
            (positionSelected.lat &&
              positionSelected.lng && [positionSelected.lat, positionSelected.lng]) ||
              positionSelected,
            mapLeaflet.getZoom()
          );
      }
    }
  };

  useEffect(() => {
    loadPavs();
  }, []);

  useEffect(() => {
    setInitPosition(positionSelected);

    if (isSearchPositionProche) {
      searchPositionProche();
    } else {
      mapLeaflet && mapLeaflet.flyTo(positionSelected, mapLeaflet.getZoom());
    }
  }, [positionSelected]);

  if (isSearchPositionProche) {
    searchPositionProche();
  }

  const iconMarker = pav => {
    return new L.Icon({
      iconUrl: require(`../../../assets/images/${
        pavSelected && pav.libelle === pavSelected.libelle
          ? 'mapmarker-pav-selected.svg'
          : 'mapmarker-pav-normal.svg'
      }`),
      iconRetinaUrl: require(`../../../assets/images/${
        pavSelected && pav.libelle === pavSelected.libelle
          ? 'mapmarker-pav-selected.svg'
          : 'mapmarker-pav-normal.svg'
      }`),
      iconAnchor: null,
      popupAnchor: true,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: [61, 71],
      iconAnchor: [30, 71],
      className: 'leaflet-div-icon'
    });
  };

  return (
    <Fragment>
      {!notSearch && (
        <InputSearchAddressGoogle
          paysCode={paysCode}
          onChangePositionProps={position => {
            onChangePositionFunc(position);
            mapLeaflet && mapLeaflet.flyTo(position, mapLeaflet.getZoom());
          }}
        />
      )}

      <MapContainer
        whenCreated={map => setMapLeaflet(map)}
        center={initPosition}
        zoom={15}
        scrollWheelZoom={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {pavs &&
          map(pavs, (pav, index) => (
            <Marker
              eventHandlers={{
                click: e => {
                  !notSelectPav && selectPav(pav);
                }
              }}
              key={index}
              position={[pav && pav.latitude, pav && pav.longitude]}
              icon={iconMarker(pav)}
            />
          ))}
      </MapContainer>
      {pavSelected ? <CardPavSelected pavSelected={pavSelected} /> : null}
    </Fragment>
  );
};

MapPavSelector.propTypes = {
  onChangePositionFunc: PropTypes.func.isRequired,
  loadPavs: PropTypes.func.isRequired,
  pavs: PropTypes.object.isRequired,
  paysCode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  pavs: state.data.entities.pavs
});

export default connect(mapStateToProps, { loadPavs })(MapPavSelector);
