import React from 'react';
import PropTypes from 'prop-types';
const CardPavSelected = ({ pavSelected }) => {
  return (
    <div className="pav-selected">
      <div className="pav-icon">
        <img src={require(`../../../assets/images/icon-pav.svg`)} />
      </div>
      <div className="pav-infos">
        <div className="pav-title">{pavSelected.libelle}</div>

        <a
          className="pav-address"
          target="_blank"
          href={`https://www.google.com/maps/place/${pavSelected.adresse}`}
        >
          {pavSelected.adresse}
        </a>
      </div>
    </div>
  );
};

CardPavSelected.propTypes = {
  pavSelected: PropTypes.object.isRequired
};
export default CardPavSelected;
