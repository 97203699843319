import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { filter } from 'lodash';
import BaseLayout from './shared/BaseLayout';

import { Carousel } from 'react-responsive-carousel';
import { API_URL } from '../constants/Config';
import { loadAppHabitants } from '../store/modules/appHabitants';


const BeneficesQuartierPage = ({ appHabitants, loadAppHabitants }) => {
  const [slideSelected, setSlideSelected] = useState(0);

  const updateCurrentSlide = index => {
    if (slideSelected !== index) {
      setSlideSelected(index);
    }
  };

  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);

  return (
    <BaseLayout appHabitants={appHabitants}>
      <header>
        <NavLink to={'/' + (slug ? slug : '')} className="link">
          <i className="far fa-times"></i>
          <span>Annuler</span>
        </NavLink>
        <h2>
          {appHabitants && appHabitants.beneficesTitre
            ? appHabitants.beneficesTitre
            : 'Les bénéfices pour mon quartier'}
        </h2>
      </header>
      <main>
        <div className="container">
          <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            useKeyboardArrows={false}
            autoPlay={false}
            selectedItem={slideSelected}
            onChange={updateCurrentSlide}
          >
            <div className="slide-content">
              <div className="slide-title">
                {appHabitants &&
                appHabitants.beneficesPages &&
                appHabitants.beneficesPages[0] &&
                appHabitants.beneficesPages[0].sousTitre
                  ? appHabitants.beneficesPages[0].sousTitre
                  : 'Un quartier plus vert !'}
              </div>
              <img
                src={
                  appHabitants &&
                  appHabitants.beneficesPages &&
                  appHabitants.beneficesPages[0] &&
                  appHabitants.beneficesPages[0].fileName
                    ? `${API_URL}/uploads/${appHabitants.beneficesPages[0].fileName}`
                    : require(`../../assets/images/upcycle-benefices-1.jpg`)
                }
              />
              <div className="slide-text">
                {appHabitants &&
                appHabitants.beneficesPages &&
                appHabitants.beneficesPages[0] &&
                appHabitants.beneficesPages[0].text
                  ? appHabitants.beneficesPages[0].text
                  : 'Mes déchets alimentaires deviennent un compost local pour fertiliser les espaces verts et les plantes .'}
              </div>
            </div>
            <div className="slide-content">
              <div className="slide-title">
                {appHabitants &&
                appHabitants.beneficesPages &&
                appHabitants.beneficesPages[1] &&
                appHabitants.beneficesPages[1].sousTitre
                  ? appHabitants.beneficesPages[1].sousTitre
                  : 'Un quartier plus propre !'}
              </div>
              <img
                src={
                  appHabitants &&
                  appHabitants.beneficesPages &&
                  appHabitants.beneficesPages[1] &&
                  appHabitants.beneficesPages[1] &&
                  appHabitants.beneficesPages[1].fileName
                    ? `${API_URL}/uploads/${appHabitants.beneficesPages[1].fileName}`
                    : require(`../../assets/images/upcycle-benefices-2.jpg`)
                }
              />
              <div className="slide-text">
                {appHabitants &&
                appHabitants.beneficesPages &&
                appHabitants.beneficesPages[1] &&
                appHabitants.beneficesPages[1].text
                  ? appHabitants.beneficesPages[1].text
                  : "Avec la collecte, je crée de l'emploi. Avec le compost je crée du lien autour de projets collectifs ."}
              </div>
            </div>
            <div className="slide-content">
              <div className="slide-title">
                {appHabitants &&
                appHabitants.beneficesPages &&
                appHabitants.beneficesPages[2] &&
                appHabitants.beneficesPages[2].sousTitre
                  ? appHabitants.beneficesPages[2].sousTitre
                  : 'Un quartier solidaire !'}
              </div>
              <img
                src={
                  appHabitants &&
                  appHabitants.beneficesPages &&
                  appHabitants.beneficesPages[2] &&
                  appHabitants.beneficesPages[2].fileName
                    ? `${API_URL}/uploads/${appHabitants.beneficesPages[2].fileName}`
                    : require(`../../assets/images/upcycle-benefices-3.jpg`)
                }
              />
              <div className="slide-text">
                {appHabitants &&
                appHabitants.beneficesPages &&
                appHabitants.beneficesPages[2] &&
                appHabitants.beneficesPages[2].text
                  ? appHabitants.beneficesPages[2].text
                  : "Avec la collecte, je crée de l'emploi. Avec le compost je crée du lien autour de projets collectifs ."}
              </div>
            </div>
          </Carousel>
          <div className="bottom-nav">
            {slideSelected > 0 && (
              <div className="prev">
                <button
                  onClick={() => {
                    setSlideSelected(slideSelected - 1);
                  }}
                  className="btn btn-secondary"
                >
                  <i className="far fa-angle-left" />
                </button>
              </div>
            )}

            {slideSelected === 2 ? (
              <div className="next">
                <NavLink className="btn" to={'/' + (slug ? slug : '')}>
                  <i className="far fa-times" />
                  <span>Fermer</span>
                </NavLink>
              </div>
            ) : (
              <div className="next">
                <button
                  onClick={() => {
                    setSlideSelected(slideSelected + 1);
                  }}
                  className="btn btn-secondary"
                >
                  <i className="far fa-angle-right" />
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
    </BaseLayout>
  );
};
const mapStateToProps = (state, props) => {
  let slug = props.match.params.slug || null;

  return {
    account: state.auth,
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {}
  };
};

export default connect(mapStateToProps, {loadAppHabitants})(BeneficesQuartierPage);
