import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BaseLayout from './shared/BaseLayout';

import logo from '../../assets/images/logo-upcycle.svg';

const Loading = ({ account, loadPavs, setUtmsource }) => {
  return (
    <BaseLayout title="home">
      <div className="container">
        <div className="loading">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="loading-text">Chargement en cours...</div>
        </div>
      </div>
      <div className="background">
        <div className="background-overlay"></div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(Loading);
