import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { map, isEmpty, isFunction } from 'lodash';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const InputSearchAddressGoogle = ({
  paysCode,
  defaultValue,
  onChangeInputProps,
  onChangePositionProps
}) => {
  const [address, setAddress] = useState((defaultValue && defaultValue) || '');

  const handleChange = address => {
    setAddress(address);
  };

  useEffect(() => {
    if (isEmpty(address)) {
      if (isFunction(onChangeInputProps) && isFunction(onChangePositionProps)) {
        onChangeInputProps('');
        onChangePositionProps(null);
      }
    }
  }, [address]);

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0].formatted_address);

        if (onChangeInputProps) {
          onChangeInputProps(results[0].formatted_address);
        }

        return getLatLng(results[0]);
      })
      .then(latLng => {
        onChangePositionProps(latLng);
      })
      .catch(error => console.error('Error', error));
  };

  const searchOptions = {
    componentRestrictions: { country: paysCode }
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Adresse',
              className: `location-search-input${suggestions.length > 0 ? ' active' : ''}`
            })}
          />
          {suggestions.length > 0 && (
            <div className="autocomplete-dropdown-container">
              {loading && <div>Chargement...</div>}
              {map(suggestions, suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item suggestion-item--active'
                  : 'suggestion-item';

                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

InputSearchAddressGoogle.propTypes = {
  paysCode: PropTypes.string.isRequired
};

export default InputSearchAddressGoogle;
