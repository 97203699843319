import React from 'react';

import { NavLink } from 'react-router-dom';
import { map } from 'lodash';

const HeaderTimeLine = ({ etapeSelected, etapesValidate, etapes, slug }) => {
  return (
    <header>
      <NavLink to={'/' + (slug ? slug : '')} className="link">
        <i className="far fa-times"></i>
        <span>Annuler</span>
      </NavLink>
      <div className="steps">
        {map(etapes, (etape, index) => (
          <div
            key={index}
            className={`step ${
              etapeSelected === index && etapesValidate.length - 1 !== index ? 'active' : ''
            } ${etapesValidate[index] ? 'achieve' : ''}`}
          >
            <div className="icon-block">
              <i></i>
              <span>{etape.titleTimeLine}</span>
            </div>
            <div className="line" />
          </div>
        ))}
      </div>
    </header>
  );
};

export default HeaderTimeLine;
