import { flow, set } from 'lodash/fp';

import { CALL_API } from '../Schemas';

const SET_UTM_SOURCE = 'upcwa/auth/SET_UTM_SOURCE';

const CREATE_ACCOUNT_REQUEST = 'upcwa/auth/CREATE_ACCOUNT_REQUEST';
const CREATE_ACCOUNT_SUCCESS = 'upcwa/auth/CREATE_ACCOUNT_SUCCESS';
const CREATE_ACCOUNT_FAILURE = 'upcwa/auth/CREATE_ACCOUNT_FAILURE';

const RECOVER_CODEPAV_REQUEST = 'upcwa/auth/RECOVER_CODEPAV_REQUEST';
const RECOVER_CODEPAV_SUCCESS = 'upcwa/auth/RECOVER_CODEPAV_SUCCESS';
const RECOVER_CODEPAV_FAILURE = 'upcwa/auth/RECOVER_CODEPAV_FAILURE';

const GET_USER_BY_EMAIL_REQUEST = 'upcwa/auth/GET_USER_BY_EMAIL_REQUEST';
const GET_USER_BY_EMAIL_SUCCESS = 'upcwa/auth/GET_USER_BY_EMAIL_SUCCESS';
const GET_USER_BY_EMAIL_FAILURE = 'upcwa/auth/GET_USER_BY_EMAIL_FAILURE';

const GET_USER_BY_CODE_REQUEST = 'upcwa/auth/GET_USER_BY_CODE_REQUEST';
const GET_USER_BY_CODE_SUCCESS = 'upcwa/auth/GET_USER_BY_CODE_SUCCESS';
const GET_USER_BY_CODE_FAILURE = 'upcwa/auth/GET_USER_BY_CODE_FAILURE';

const CHECK_EMAIL_EXIST_REQUEST = 'upcwa/auth/CHECK_EMAIL_EXIST_REQUEST';
const CHECK_EMAIL_EXIST_SUCCESS = 'upcwa/auth/CHECK_EMAIL_EXIST_SUCCESS';
const CHECK_EMAIL_EXIST_FAILURE = 'upcwa/auth/CHECK_EMAIL_EXIST_FAILURE';

const CREATE_SIGNALEMENT_REQUEST = 'upcwa/auth/CREATE_SIGNALEMENT_REQUEST';
const CREATE_SIGNALEMENT_SUCCESS = 'upcwa/auth/CREATE_SIGNALEMENT_SUCCESS';
const CREATE_SIGNALEMENT_FAILURE = 'upcwa/auth/CREATE_SIGNALEMENT_FAILURE';
const SET_FILTER = 'htwp/globals/SET_FILTER';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_UTM_SOURCE:
      return flow(set('loading', false), set('utm_source', action.utmsource))(state);
    case CREATE_ACCOUNT_REQUEST:
      return flow(set('loading', true))(state);
    case CREATE_ACCOUNT_SUCCESS:
      return flow(set('loading', false), set('user', action.response))(state);
    case CREATE_ACCOUNT_FAILURE:
      return flow(set('loading', false), set('user', null))(state);

    case RECOVER_CODEPAV_REQUEST:
      return flow(set('loading', true))(state);
    case RECOVER_CODEPAV_SUCCESS:
      return flow(set('loading', false), set('user', action.response))(state);
    case RECOVER_CODEPAV_FAILURE:
      return flow(set('loading', false))(state);

    case GET_USER_BY_EMAIL_REQUEST:
      return flow(set('loading', true))(state);
    case GET_USER_BY_EMAIL_SUCCESS:
      return flow(set('loading', false), set('user', action.response))(state);
    case GET_USER_BY_EMAIL_FAILURE:
      return flow(set('loading', false))(state);

    case GET_USER_BY_CODE_REQUEST:
      return flow(set('loading', true))(state);
    case GET_USER_BY_CODE_SUCCESS:
      return flow(set('loading', false), set('user', action.response))(state);
    case GET_USER_BY_CODE_FAILURE:
      return flow(set('loading', false))(state);

    case SET_FILTER:
      console.log('on va change le state : ', action.filter);
      return {
        ...state,
        front: {
          firstColor: action.filter
        }
      };
    default:
      return state;
  }
}

export function setUtmsource(utmsource) {
  return {
    type: SET_UTM_SOURCE,
    utmsource
  };
}
export function createAccount(data) {
  return {
    [CALL_API]: {
      types: [CREATE_ACCOUNT_REQUEST, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_FAILURE],
      method: 'POST',
      endpoint: '/users/create',
      body: data
    }
  };
}

export function recoverCodePAV(email) {
  return {
    [CALL_API]: {
      types: [RECOVER_CODEPAV_REQUEST, RECOVER_CODEPAV_SUCCESS, RECOVER_CODEPAV_FAILURE],
      method: 'POST',
      endpoint: '/users/recover',
      body: {
        email
      }
    }
  };
}

export function getUserByEmail(email) {
  return {
    [CALL_API]: {
      types: [GET_USER_BY_EMAIL_REQUEST, GET_USER_BY_EMAIL_SUCCESS, GET_USER_BY_EMAIL_FAILURE],
      method: 'POST',
      endpoint: '/users/getUser/email',
      body: {
        email
      }
    }
  };
}

export function getUserByCode(code) {
  return {
    [CALL_API]: {
      types: [GET_USER_BY_CODE_REQUEST, GET_USER_BY_CODE_SUCCESS, GET_USER_BY_CODE_FAILURE],
      method: 'POST',
      endpoint: '/users/getUser/code',
      body: {
        code
      }
    }
  };
}

export function checkEmailExist(email) {
  return {
    [CALL_API]: {
      types: [CHECK_EMAIL_EXIST_REQUEST, CHECK_EMAIL_EXIST_SUCCESS, CHECK_EMAIL_EXIST_FAILURE],
      method: 'POST',
      endpoint: '/users/checkEmail',
      body: {
        email
      }
    }
  };
}

export function createSignalement(data) {
  return {
    [CALL_API]: {
      types: [CREATE_SIGNALEMENT_REQUEST, CREATE_SIGNALEMENT_SUCCESS, CREATE_SIGNALEMENT_FAILURE],
      method: 'POST',
      endpoint: '/alertes',
      body: data
    }
  };
}

export function changeColor(filter) {
  return {
    type: SET_FILTER,
    filter
  };
}
