import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { NavLink, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';

import { cloneDeep, map, find, slice, filter } from 'lodash';

import BaseLayout from './shared/BaseLayout';
import HeaderTimeLine from '../components/shared/HeaderTimeLine';

import MapPavSelector from '../components/shared/MapPavSelector';
import { getUserByCode, getUserByEmail, createSignalement } from '../store/modules/auth';
import { validateEmail } from '../utils/StringValidator';
import { loadAppHabitants } from '../store/modules/appHabitants';

import { loadPavs } from '../store/modules/pavs';

import logo from '../../assets/images/logo-upcycle-couleur.svg';
import bonhup from '../../assets/images/bonhup-happy.svg';

const SignalementPage = ({
  loadPavs,
  pavs,
  account,
  appHabitants,
  getUserByEmail,
  getUserByCode,
  createSignalement,
  loadAppHabitants
}) => {
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      loadAppHabitants(slug);
    }
  }, [slug]);
  
  const pavAssocieUser =
    account.user && find(pavs, pav => pav && pav.pav_id === account.user.pav_id);

  const [pavSelected, setPavSelected] = useState(null);
  const [etapeSelected, setEtapeSelected] = useState(0);

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [errorUser, setErrorUser] = useState(null);

  const [errorSignalement, setErrorSignalement] = useState(null);

  const [formNotCompleted, setFormNotCompleted] = useState(true);

  const [problemesOptions, setProblemesOptions] = useState([
    {
      icon: 'fas fa-box-up',
      libelle:
        appHabitants && appHabitants.signalement && appHabitants.signalement.signalement3
          ? appHabitants.signalement.signalement3
          : 'Besoin de collecte',
      type: 'SBC',
      selected: false
    },
    {
      icon: 'fas fa-tools',
      libelle:
        appHabitants && appHabitants.signalement && appHabitants.signalement.signalement4
          ? appHabitants.signalement.signalement4
          : 'Besoin de réparation',
      type: 'SBR',
      selected: false
    },
    {
      icon: 'fas fa-broom',
      libelle:
        appHabitants && appHabitants.signalement && appHabitants.signalement.signalement5
          ? appHabitants.signalement.signalement5
          : 'Besoin de nettoyage',
      type: 'SBN',
      selected: false
    },
    { icon: 'fas fa-sack', libelle: 'Besoin de sacs Kraft', type: 'SBS', selected: false }
  ]);
  const [problemeSelected, setProblemeSelected] = useState(null);

  const [etapesValidate, setEtapesValidate] = useState([false, false, false, false]);

  const [positionSelected, setPositionSelected] = useState(
    (pavAssocieUser && [pavAssocieUser.latitude, pavAssocieUser.longitude]) || [
      48.1107924,
      -1.6677123
    ]
  );

  useEffect(() => {
    loadPavs();

    if (account.user) {
      setEtapeSelected(1);
      const validate = cloneDeep(etapesValidate);
      validate[0] = true;
      setEtapesValidate(validate);
    }
    if (appHabitants) {
      if (!appHabitants.signaler) {
        // si le appHabitants n'a pas cocher la case, on enleve le besoin de kraft
        setProblemesOptions(slice(problemesOptions, 0, problemesOptions.length - 1));
      }
    }
  }, []);

  const prevEtape = () => {
    setEtapeSelected(etapeSelected - 1);
  };

  const selectProblem = index => {
    // reset selected
    map(problemesOptions, option => (option.selected = false));

    const problem = problemesOptions[index];
    problem.selected = true;
    setProblemeSelected(problem);
  };

  const selectPav = pav => {
    setPavSelected(pav);
  };

  const submitUserForm = () => {
    if (email.length > 1) {
      if (validateEmail(email)) {
        getUserByEmail(email)
          .then(user => {
            const pavAssocieUser = find(pavs, pav => pav && pav.pav_id === user.response.pav_id);
            if (pavAssocieUser) {
              setPositionSelected([pavAssocieUser.latitude, pavAssocieUser.longitude]);
            }

            window.scrollTo(0, 0);
            setEtapeSelected(etapeSelected + 1);
            const validate = cloneDeep(etapesValidate);
            validate[etapeSelected] = true;
            setEtapesValidate(validate);
          })
          .catch(err => {
            setErrorUser(err);
          });
      } else {
        setErrorUser('Email non valide !');
      }
    }
    if (code.length === 5) {
      getUserByCode(code)
        .then(user => {
          const pavAssocieUser = find(pavs, pav => pav && pav.pav_id === user.response.pav_id);
          if (pavAssocieUser) {
            setPositionSelected([pavAssocieUser.latitude, pavAssocieUser.longitude]);
          }
          window.scrollTo(0, 0);
          setEtapeSelected(etapeSelected + 1);
          const validate = cloneDeep(etapesValidate);
          validate[etapeSelected] = true;
          setEtapesValidate(validate);
        })
        .catch(err => {
          setErrorUser(err);
        });
    }
  };

  const postSignalement = () => {
    moment.locale('fr');

    return createSignalement({
      pav_id: pavSelected.pav_id,
      type: problemeSelected.type,
      libelle: `Usager : ${problemeSelected.libelle} - ${moment().format(
        'DD/MM/YYYY'
      )} - ${moment().format('HH:mm')}`
    })
      .then(() => {
        window.scrollTo(0, 0);
        setEtapeSelected(etapeSelected + 1);
        const validate = cloneDeep(etapesValidate);
        validate[etapeSelected] = true;
        validate[3] = true;
        setEtapesValidate(validate);
      })
      .catch(err => setErrorSignalement(err));
  };

  const etapes = [
    {
      titleTimeLine: appHabitants?.signalement?.arianeSignalement1
        ? appHabitants.signalement.arianeSignalement1
        : 'Compte',
      content: (
        <Fragment>
          <h2>Je m'authentifie</h2>

          <div className="form-small">
            <div className="field">
              <input
                type="email"
                value={email}
                onChange={e => {
                  if (email.length > 1) {
                    setFormNotCompleted(false);
                  } else {
                    setFormNotCompleted(true);
                  }
                  setEmail(e.target.value);
                  setCode('');
                }}
              />
              <label>Adresse email</label>
            </div>
            <div className="ou">OU</div>
            <div className="field">
              <input
                type="text"
                value={code}
                onChange={e => {
                  if (code.length > 1) {
                    setFormNotCompleted(false);
                  } else {
                    setFormNotCompleted(true);
                  }

                  setCode(e.target.value.toUpperCase());
                  setEmail('');
                }}
              />
              <label>Code utilisateur</label>
            </div>
          </div>

          {errorUser && <p style={{ color: 'red' }}>{errorUser}</p>}

          <div className="bottom-nav">
            <div>
              <button
                disabled={formNotCompleted}
                onClick={() => {
                  setErrorUser(null);
                  submitUserForm();
                }}
                className="btn"
              >
                <span>Suivant</span>
                <i className="far fa-angle-right" />
              </button>
            </div>
          </div>
        </Fragment>
      )
    },
    {
      titleTimeLine: appHabitants?.signalement?.arianeSignalement2
        ? appHabitants.signalement.arianeSignalement2
        : 'PAV',
      content: (
        <Fragment>
          <h2>
            {appHabitants?.signalement?.signalement1 ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: appHabitants.signalement.signalement1
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: `Quel point d'apport volontaire&nbsp;?`
                }}
              />
            )}
          </h2>
          <div className="map">
            <MapPavSelector
              paysCode={(account.user && account.user.paysCode && account.user.paysCode) || 'fr'}
              onChangePositionFunc={position => {
                setPositionSelected(position);
              }}
              pavSelected={pavSelected}
              positionSelected={positionSelected}
              selectPav={pav => selectPav(pav)}
            />
          </div>

          <div className="bottom-nav">
            <div className="next">
              <button
                disabled={!pavSelected}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setEtapeSelected(etapeSelected + 1);
                  const validate = cloneDeep(etapesValidate);
                  validate[etapeSelected] = true;
                  setEtapesValidate(validate);
                }}
                className="btn"
              >
                <span>Suivant</span>
                <i className="far fa-angle-right" />
              </button>
            </div>
          </div>
        </Fragment>
      )
    },
    {
      titleTimeLine:
        appHabitants && appHabitants.signalement && appHabitants.signalement.arianeSignalement3
          ? appHabitants.signalement.arianeSignalement3
          : 'Problème',
      content: (
        <Fragment>
          {appHabitants?.signalement?.signalement2 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: `<h2>${appHabitants.signalement.signalement2}</h2>`
              }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: `<h2>Quelle est la nature du problème&nbsp;?</h2>`
              }}
            />
          )}
          <div className="grid">
            {map(problemesOptions, (probleme, index) => (
              <div key={index} className="col-12">
                <div
                  key={index}
                  className={`btn btn-tertiary btn-medium ${probleme.class} ${
                    probleme.selected ? 'active' : ''
                  } `}
                  onClick={() => selectProblem(index)}
                >
                  <i className={probleme.icon} />
                  <span>{probleme.libelle}</span>
                </div>
              </div>
            ))}
          </div>

          {errorSignalement && <p style={{ color: 'red' }}>{errorSignalement}</p>}
          <div className="bottom-nav">
            <div className="prev">
              <button onClick={() => prevEtape()} className="btn btn-secondary">
                <i className="far fa-angle-left" />
                <span>Précédent</span>
              </button>
            </div>
            <div className="next">
              <button
                disabled={!problemeSelected}
                onClick={() => {
                  postSignalement();
                }}
                className="btn"
              >
                <span>Suivant</span>
                <i className="far fa-angle-right" />
              </button>
            </div>
          </div>
        </Fragment>
      )
    },
    {
      titleTimeLine:
        appHabitants && appHabitants.signalement && appHabitants.signalement.arianeSignalement4
          ? appHabitants.signalement.arianeSignalement4
          : 'Fin',
      content: (
        <div className="achieve-step-message">
          {appHabitants && appHabitants.valueEditorRemerciements ? (
            <div
              className="w100"
              dangerouslySetInnerHTML={{ __html: appHabitants.valueEditorRemerciements }}
            />
          ) : (
            <Fragment>
              <h2>Merci pour votre aide&nbsp;!</h2>
              <img className="bonhup-happy" src={bonhup} />
              <h3>Grâce à vous , nous somme prévenus , une équipe va intervenir .</h3>
            </Fragment>
          )}
          <div className="btn-group flexColumn center">
            <NavLink className="btn" to={slug ? '/' + slug + '/trouver-pav' : '/trouver-pav'}>
              {appHabitants && appHabitants.signalement && appHabitants.signalement.signalement6
                ? appHabitants.signalement.signalement6
                : 'Trouver un PAV à proximité'}
            </NavLink>

            <NavLink className="btn btn-quaternary" to={'/' + (slug ? slug : '')}>
              Fermer
            </NavLink>
            <div className="version">
              <img className="logo-version" src={logo} />
            </div>
          </div>
        </div>
      )
    }
  ];

  return (
    <BaseLayout appHabitants={appHabitants}>
      <HeaderTimeLine
        etapeSelected={etapeSelected}
        etapes={etapes}
        etapesValidate={etapesValidate}
        slug={slug}
      />
      <main>
        <div className="container">{etapes[etapeSelected].content}</div>
      </main>
    </BaseLayout>
  );
};

const mapStateToProps = (state, props) => {
  let slug = props.match.params.slug || null;

  return {
    account: state.auth,
    pavs: state.data.entities.pavs,
    appHabitants: slug ? state.data.entities.appHabitants[slug] : {}
  };
};

export default connect(mapStateToProps, {
  loadPavs,
  getUserByEmail,
  getUserByCode,
  createSignalement,
  loadAppHabitants
})(SignalementPage);
